import React, { useEffect } from "react";
import "./OurTeam.css";
import nirmala from "../../images/Nirmala_Mehendale-2-1.jpg";
import lopa from "../../images/lopa.jpg";
import sonal from "../../images/Trustee-Sonal-Singh_resized.jpg";
import sunita from "../../images/Trustee-Sunita-Machado-1.jpg";
import venkat from "../../images/Venkat_500x500.jpg";
import hansie from "../../images/Hansie_1.jpg";
import nijo from "../../images/nijo.jpg";
import arya from "../../images/Arya-Karandikar-1.jpg";
import samuel from "../../images/Samuel.jpg";
import basilica from "../../images/Basilica.png";
import vedant from "../../images/vedant.png";

const OurTeam = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const interns = [
    { name: "Hansie Aloj", role: "Website Manager", img: hansie },
    { name: "Nijo Ninan", role: "Website Manager", img: nijo },
    { name: "Basilica Anthony", role: "Website Manager", img: basilica },
    { name: "Vedant Sakpal", role: "Website Manager", img: vedant },
    { name: "Arya Karandikar", role: "Media and Communication", img: arya },
    { name: "Samuel", role: "Illustrator and Designer", img: samuel },
  ];

  return (
    <section className="team">
      <h1 className="team-heading">Our Team</h1>
      <div className="members-section">
        <div className="row bboKU">
          <h2 className="sub-heading">Building Blocks of KU</h2>
          <div className="person-card col-lg-3 col-md-4">
            <div className="col card-col">
              <div className="row member-img">
                <img
                  className="img-fluid member-photo"
                  src={nirmala}
                  alt="Nirmala Peters - Mehendale"
                />
              </div>
              <div className="row member-name">
                <p>Nirmala Peters - Mehendale</p>
              </div>
              <div className="row member-designation">
                <p>Founder Trustee and General Secretary</p>
              </div>
            </div>
          </div>
          <div className="person-card col-lg-3 col-md-4">
            <div className="col card-col">
              <div className="row member-img">
                <img className="img-fluid member-photo" src={lopa} alt="Lopa Vyas" />
              </div>
              <div className="row member-name">
                <p>Lopa Vyas</p>
              </div>
              <div className="row member-designation">
                <p>Founder Trustee</p>
              </div>
            </div>
          </div>
          <div className="person-card col-lg-3 col-md-4">
            <div className="col card-col">
              <div className="row member-img">
                <img className="img-fluid member-photo" src={sonal} alt="Sonal Singh" />
              </div>
              <div className="row member-name">
                <p>Sonal Singh</p>
              </div>
              <div className="row member-designation">
                <p>Trustee</p>
              </div>
            </div>
          </div>
          <div className="person-card col-lg-3 col-md-4">
            <div className="col card-col">
              <div className="row member-img">
                <img
                  className="img-fluid member-photo"
                  src={sunita}
                  alt="Sunita Machado"
                />
              </div>
              <div className="row member-name">
                <p>Sunita Machado</p>
              </div>
              <div className="row member-designation">
                <p>Trustee</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="members-section">
        <div className="row bboKU">
          <h2 className="sub-heading">KU Mentor</h2>
          <div className="person-card">
            <div className="col card-col">
              <div className="row member-img">
                <img
                  className="img-fluid member-photo"
                  src={venkat}
                  alt="Venkat N. Krishnan"
                />
              </div>
              <div className="row member-name">
                <p>Venkat N. Krishnan</p>
              </div>
              <div className="row member-designation">
                <p>Mentor</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="members-section">
        <div className="row bboKU">
          <h2 className="sub-heading">KU Interns</h2>
          {interns.map((intern, index) => (
            <div className="person-card col-lg-4 col-md-6" key={index}>
              <div className="col card-col">
                <div className="row member-img">
                  <img
                    className="img-fluid member-photo"
                    src={intern.img}
                    alt={intern.name}
                  />
                </div>
                <div className="row member-name">
                  <p>{intern.name}</p>
                </div>
                <div className="row member-designation">
                  <p>{intern.role}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
